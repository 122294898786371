<script>
import { useStore } from 'vuex'
import { onBeforeMount, defineAsyncComponent, shallowRef, onUpdated, computed } from 'vue'
import { getEverflow, getJornayaLeadIdToken, getTrustedForm, scrollToElement } from "@/util/getters"
import SpeakToAgent from '@/components/quotes-questionnaire-v2/components/SpeakToAgent'
import lvProgressbar from 'lightvue/progress-bar'

const QuestionnaireSteps = {
  Zip: {
    component: shallowRef(defineAsyncComponent(() => import('./steps/Zip'))),
    questionText: 'What is your zip code?'
  },
  LeaveBehindAds: {
    component: shallowRef(defineAsyncComponent(() => import('../shared/LeaveBehindAds'))),
    questionText: '',
    doNotShowSpeakToAgent: true
  },
  DoYouHaveLifeInsurance: {
    component: shallowRef(defineAsyncComponent(() => import('./steps/DoYouHaveLifeInsurance'))),
    questionText: 'Do you currently have life insurance?'
  },
  HaveYouUsedTobacco: {
    component: shallowRef(defineAsyncComponent(() => import('./steps/HaveYouUsedTobacco'))),
    questionText: 'Have you used Tobacco Products within the last 12 months?'
  },
  MarriageStatus: {
    component: shallowRef(defineAsyncComponent(() => import('./steps/MarriageStatus'))),
    questionText: 'Are you currently married?'
  },
  ChildrenStatus: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/ChildrenStatus'))),
    questionText: 'Do you have children?'
  },
  DateOfBirth: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/DateOfBirth'))),
    questionText: 'What is your date of birth?'
  },
  ReasonForLifeInsurance: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/ReasonForLifeInsurance'))),
    questionText: 'Why are you looking for life insurance?'
  },
  Height: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Height'))),
    questionText: 'What is your height?'
  },
  Weight: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Weight'))),
    questionText: 'What is your weight (lbs)?'
  },
  Treated_Or_Prescribed_Last_Five_Years: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Treated_Or_Prescribed_Last_Five_Years'))),
    questionText: 'In the past 5 years have you been treated or prescribed medication for any of the following conditions?'
  },
  HospitalizedDueToAnxiety: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/HospitalizedDueToAnxiety'))),
    questionText: 'Have you been hospitalized or missed more than 1 week of work due to anxiety, depression, or bipolar disorder?'
  },
  IsMedicalConditionListed: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/IsMedicalConditionListed'))),
    questionText: 'Is your other medical condition any of the following?'
  },
  EmploymentStatus: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/EmploymentStatus'))),
    questionText: 'Are you currently employed?'
  },
  Name: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Name'))),
    questionText: 'What is your name?'
  },
  Email: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Email'))),
    questionText: 'What is your email?'
  },
  Phone: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Phone'))),
    questionText: 'What is your phone?'
  },
  ThankYou: {
    component: shallowRef(defineAsyncComponent(() => import ('../shared/ThankYou'))),
    questionText: '',
    doNotShowSpeakToAgent: true
  },
}

export default {
  name: "QuestionnaireRoot_v2",

  components: {
    SpeakToAgent,
    lvProgressbar
  },

  setup() {
    const store = useStore()
    const currentStep = computed(() => store.state.currentStep)
    const currentProgress = computed(() => store.state.progress)
    const isLoading = computed(() => store.state.loading)

    const scrollOptions = {
      landScapeSelector: '.main-container',
      landScapeOffset: 70,
      portraitSelector: '#app',
    }


    const goToNextStep = (step) => {
      store.commit('SET_IS_LOADING', true)
      store.dispatch('questionnaire_v2/goToNextStep', step)
    }

    onBeforeMount(() => {
      //get zip data from local storage...
      const zip_data = JSON.parse(window.localStorage.getItem('zip_data'))
      if (zip_data) {
        store.commit('SET_FIELDS', zip_data)
      }
      //clear local storage
      window.localStorage.clear()

      //check if user is has refreshed and is on flow without zip code
      const searchParams = new URLSearchParams(location.search)
      if (searchParams.get('zip_code') && !store.state.fields['zip_code']) {
        window.location.href = window.location.pathname
        return
      }

      //get jornaya lead id
      window.loadJornayaLeadId('807f6757-b597-40d4-e090-9aa3235e89c0')
      getJornayaLeadIdToken().then(jornaya_id => {
        store.commit('SET_FIELD', {field: 'jornayaId', value: jornaya_id})
      })

      //get everflow
      getEverflow().then((ef_transaction_id) => {
        store.commit('SET_FIELD', {field: 'ef_transaction_id', value: ef_transaction_id})
      })

      //set trusted form
      getTrustedForm().then(({trustedFormUrl, trustedFormId}) => {
        const trusted = [
          {field: 'trustedFormId', value: trustedFormId},
          {field: 'trustedFormUrl', value: trustedFormUrl}
        ]
        store.commit('SET_FIELDS', trusted)
      })

      //set url param fields
      store.commit('SET_FIELDS_FROM_URL')

      goToNextStep('StartPath')
    })

    onUpdated(() => {
      scrollToElement(scrollOptions)
    })

    return {
      isLoading,
      currentProgress,
      currentStep,
      QuestionnaireSteps,
      goToNextStep
    }
  }
}
</script>

<template>
  <div class="section-container">
    <div class="wrapper question-root-wrapper">
      <div class="inner-wrapper question-root-inner-wrapper">
        <div class="progress-container" v-if="currentStep !== 'LeaveBehindAds'">
          <lv-progressbar
            :color="'#00753d'"
            :value="currentProgress"
            style="width: 100%; height: 30px"
          />
        </div>
        <div class="row button-answers-row" v-if="!isLoading">
          <div class="box button-answers-box" v-if="QuestionnaireSteps[currentStep].questionText">
            <h2 class="main-heading button-answers-heading">{{QuestionnaireSteps[currentStep].questionText}}</h2>
          </div>
        </div>

        <component
          :is="QuestionnaireSteps[currentStep].component.value"
          @next-step="goToNextStep"
        >
        </component>

        <SpeakToAgent v-if="!QuestionnaireSteps[currentStep].doNotShowSpeakToAgent"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../assets/styles/component_styles/questionnaire/questionnaire-root.scss';
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 20px 0 40px 0;
  .main-heading {
    font-size: 32px;
  }
}
.lv-progressbar--determinate .lv-progressbar__value--animate {
  transition: none;
}

.question-root-wrapper {
  //this overwrites the original greenish background, keep things separate from the first version
  background-color: #fff;
  max-width: 800px;
  .question-root-inner-wrapper {
    justify-content: flex-start;
  }
}
</style>

<style lang="scss">
.lv-progressbar--determinate .lv-progressbar--determinate__label {
  font-size: 20px;
  margin-top: 3px;
}
</style>