<script setup>
import { getRetreaverNumber } from '@/util/getters'
import { ref, onBeforeMount } from 'vue'

const retreaver = ref({
  number: '18773300902',
  formattedNumber: '(877) 330-0902'
})

onBeforeMount(() => {
  getRetreaverNumber().then(({retreaverNumber, retreaverNumberFormatted}) => {
    retreaver.value.number = retreaverNumber
    retreaver.value.formattedNumber = retreaverNumberFormatted
  })
})
</script>

<template>
  <div class="speak-to-agent-container">
    <a class="agent-number-text" :href="`tel:${retreaver.number}`">OR CALL <span class="agent-number">{{retreaver.formattedNumber}}</span></a>
  </div>
</template>

<style scoped lang="scss">
.speak-to-agent-container {
  margin-top: 20px;
  .agent-number-text {
    color: #1e1e1e;
    font-family: sans-serif;
    text-decoration: none;
    span {
      font-weight: 700;
      cursor: pointer;
      transition: all .2s ease-in-out;
      &:hover {
        transition: all .2s ease-in-out;
        color: #1e1e1ecf;
      }
    }
  }
}
</style>