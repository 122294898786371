<script setup>
import { getRetreaverNumber } from '@/util/getters'
import { ref, onBeforeMount } from 'vue'

const retreaver = ref({
  number: '18773300902',
  formattedNumber: '(877) 330-0902'
})

onBeforeMount(() => {
  getRetreaverNumber().then(({retreaverNumber, retreaverNumberFormatted}) => {
    retreaver.value.number = retreaverNumber
    retreaver.value.formattedNumber = retreaverNumberFormatted
  })
})

</script>

<template>
  <div class="header">
    <div class="header-container">
      <a href="/">
        <img src="../../assets/img/logos/AFL-big-green-logo.png" alt="American-First-Life-Logo">
      </a>
      <a class="border" :href="`tel:${retreaver.number}`"><span class="phone-prefix">+1</span> {{retreaver.formattedNumber}}</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/styles/constants';

img {
  width: 100%;
  max-width: 220px;
}
a.border {
  font-family: sans-serif;
  font-size: 20px;
  text-decoration: none;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: $dark-green;
  color: $main_background;
  &:hover {
    color: white;
  }
}

@media (max-width: 500px) {
  a.border {
    font-size: 18px;
    margin-left: 10px;
    text-align: center;
    .phone-prefix {
      display: none;
    }
  }
  img {
    max-width: 180px;
  }
}

@media (max-width: 368px) {
  a.border {
    padding: 4px;
  }
}
</style>