<script>
export default {
  name: "PartnersFooter"
}
</script>

<template>
  <section class="partners-footer-section">
    <div class="wrapper">
      <div class="life-insurance-companies-container">
        <div class="img-logo-container aig-logo-container"></div>

        <div class="img-logo-container all-state-logo-container">
        </div>

        <div class="img-logo-container american-general-logo-container"></div>

        <div class="img-logo-container american-income-life-insurance-logo-container">
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.partners-footer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background-color: rgb(242, 244, 244);
  padding-top: 0;
  padding-bottom: 20px;
  .wrapper {
    width: 100%;
    max-width: 1200px;
    .life-insurance-companies-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .img-logo-container {
        max-width: 320px;
        height: 90px;
        width: 100%;
        filter: invert(83%) sepia(0%) saturate(34%) hue-rotate(146deg) brightness(93%) contrast(84%);
      }
      .aig-logo-container {
        background-image: url("../../assets/img/logos/insurance-company-logos/aig-logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 40px;
      }

      .all-state-logo-container {
        background-image: url("../../assets/img/logos/insurance-company-logos/all-state-logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .american-income-life-insurance-logo-container {
        background-image: url("../../assets/img/logos/insurance-company-logos/american-income-life-insurance-logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .american-general-logo-container {
        background-image: url("../../assets/img/logos/insurance-company-logos/american-general-logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 75px;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .partners-footer-section .wrapper .life-insurance-companies-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 390px) {
  .partners-footer-section .wrapper .life-insurance-companies-container {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
}
</style>