<script>
import PartnersFooter from "@/components/shared/PartnersFooter"
export default {
  name: "Footer",
  components: { PartnersFooter }
}
</script>

<template>
  <PartnersFooter/>
  <div class="footer">
    <div class="footer-container">
      <div class="wrapper">
        <div class="logo"></div>

        <div class="footer-nav">
          <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
          <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
        </div>
      </div>

      <div class="copyright-text">
        Copyright © 2022 American First Life | All rights reserved.
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.logo {
  //background: left / contain no-repeat url("../../assets/img/logos/logo.png");
  background: left / contain no-repeat url("../../assets/img/logos/AFL-big-green-logo.png");
  width: 100%;
  height: 45px;
}


@media screen and (max-width: 500px) {
  .logo {
    display: none;
  }
  .footer .footer-container .wrapper .footer-nav {
    margin-top: 10px;
    justify-content: center;
  }
}
</style>
