<script>
export default {
  name: "FinalExpenseRates"
}
</script>

<template>
  <section class="final-expense-rate-section">
    <div class="wrapper">
      <h2 class="final-expense-rate-title">No Exam Final Expense Rates!</h2>
    </div>
  </section>
</template>

<style scoped lang="scss">
.final-expense-rate-section {
  margin-top: 60px;
  background-image: url("../../assets/img/beach-family.jpg");
  background-size: cover;
  background-position: center 40%;
  height: 180px;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d2d2db3;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.final-expense-rate-title {
  color: #fff;
  font-family: sans-serif;
  font-size: 38px;
  font-weight: 300;
  text-align: center;
}

//ipad landscape
@media (orientation: landscape) and (max-width: 1024px) {
  .final-expense-rate-section {
    height: 100px;
    .final-expense-rate-title {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 425px) {
  .final-expense-rate-section {
    height: 130px;
    .final-expense-rate-title {
      font-size: 28px;
    }
  }
}
</style>